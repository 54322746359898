* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  outline: 0;
}
body {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex-wrap: nowrap;
  align-content: stretch;
  align-items: stretch;
  font-size: 1rem;
  font-family: 'Raleway', serif;
  color: #333 !important;
}
main {
  display: flex;
  flex: 1;
  flex-direction: column;
}
a {
  color: #333;
}
p {
  font-size: 1.2rem;
  line-height: 2rem;
  text-align: justify;
  margin: 0 auto;
  padding: 1.5rem 0;
}
.right {
  float: right;
}
.left {
  float: left;
}
.clear {
  clear: both;
}
header,
section {
  clear: both;
  position: relative;
  margin: 0 auto;
  width: 100%;
  max-width: 960px;
  padding: 0 2rem;
}
header .excerpt,
section .excerpt {
  margin: 2rem 0;
}
svg.address {
  display: block;
  position: relative;
  width: 100%;
  padding: 0.5rem;
  height: 3.5rem;
  background: #79bdf7;
  cursor: pointer;
  transition: all 0.25s ease-in;
}
svg.address text {
  fill: #fff;
}
svg.address:hover {
  background: #f76767;
}
svg.address:after {
  content: 'XYlLKAJLKJFLKFJLKFJLKFJ';
  display: block;
}
input {
  display: block;
  margin: 2rem auto 0.5rem;
  font-family: 'Raleway';
  font-weight: 800;
  font-size: 1.5rem;
  line-height: 1.3em;
  padding: 0.6rem;
  width: 100%;
  border: 0.25rem solid #333;
  color: #333;
  caret-color: #333;
  transition: 0.5s ease all;
}
input:focus,
input:not([disabled]):hover {
  font-family: 'Raleway';
  color: #333;
}
.icon {
  font-size: 1.8rem;
  vertical-align: middle;
  padding: 0 1rem;
}
.warning {
  border-color: #ff9244;
  background-color: #ff9244;
  color: #fff;
  caret-color: #ff9244;
  padding: 0.6rem;
}
.warning a {
  color: #fff;
}
::placeholder {
  font-family: 'Raleway';
  font-weight: 800;
  color: #333;
  font-size: 1.5rem;
  line-height: 1.3em;
}
.warning::placeholder {
  color: #fff;
}
li {
  display: inline-block;
  padding: 0.2rem 1rem;
}
header {
  padding: 0 2em;
  text-align: center;
}
header a {
  text-decoration: none;
}
header h1 {
  font-family: 'Josefin Slab', serif;
  font-size: 6rem;
  padding: 2rem 0 1rem;
}
header h1 a {
  border-bottom: none;
  text-decoration: none;
  position: relative;
}
header h1 a::after {
  position: absolute;
  content: 'beta';
  font-size: 1rem;
  top: 25px;
  right: -13px;
  writing-mode: vertical-rl;
  text-orientation: mixed;
}
.statistics {
  background: #333;
  color: #fff;
  font-size: 0.8rem;
  white-space: pre;
  padding: 0.1rem;
  display: flex;
}
.statistics li {
  display: inline-block;
  flex: 0.5;
  padding: 0.2rem 0;
}
.statistics li em {
  display: inline-block;
  margin-left: 0.5rem;
  padding: 0 0.75rem;
  color: #333;
  background: #fff;
}
.navigation {
  padding-top: 1rem;
}
.navigation a {
  font-weight: bold;
  color: #888;
  border-bottom: 0.25rem solid #888;
}
.navigation a.active {
  color: #333;
  border-bottom: 0.25rem solid #333;
}
.navigation ul {
  text-align: left;
  display: flex;
}
.navigation ul li:first-child {
  padding-left: 0;
}
.navigation ul li:last-child {
  padding-right: 0;
}
.ledger {
  background: #333;
  color: #fff;
  display: flex;
  align-items: center;
  padding: 0.5rem;
}
.ledger .info {
  flex: 1;
  padding: 0 2rem;
}
.ledger .info em {
  background: #fff;
  color: #333;
  margin: 0 0.5rem;
  padding: 0 0.5rem;
}
.ledger button {
  padding: 0.2rem 1rem;
  background: #fff;
  color: #333;
  font-family: 'Raleway', serif;
  cursor: pointer;
  text-transform: uppercase;
  height: 2rem;
  border: none;
}
.ledger button:hover {
  border-bottom: 5px solid #a4cc9c;
}
.loader::after {
  content: " ";
  display: block;
  width: 2rem;
  height: 2rem;
  margin: 0;
  border-radius: 50%;
  border: 1px solid #fff;
  border-color: #fff transparent #fff transparent;
  animation: loader 1.2s linear infinite;
}
.loader.dark::after {
  border: 1px solid #333;
  border-color: #333 transparent #fff transparent;
}
.loader.big {
  width: 256px;
  height: 256px;
  padding-top: 78px;
  padding-left: 78px;
  background: #333;
}
.loader.big::after {
  border-width: 5px;
  width: 100px;
  height: 100px;
  top: 50%;
  left: 50%;
}
.transactions {
  margin: 0.5rem 0;
}
.transactions li {
  display: flex;
  background: #ccc;
  align-items: center;
  padding: 0.5rem;
  margin-bottom: 0.5rem;
  line-height: 2rem;
  transition: all 1s ease;
}
.transactions li.pending {
  background-color: #ccc;
  color: #fff;
}
.transactions li.confirmed {
  background-color: #a4cc9c;
  color: #fff;
}
.transactions a {
  color: #fff;
}
.transactions .details {
  flex: 1;
  display: flex;
  justify-content: flex-end;
}
.transactions .time {
  flex: 1;
  padding-left: 1rem;
  text-align: right;
}
.transactions .amount {
  text-align: left;
  padding-left: 1rem;
}
.transactions .search {
  text-align: right;
  padding: 0 0.5rem;
}
.transactions .status {
  background: rgba(0,0,0,0.1);
  padding: 0 0.5rem;
  margin: 0;
}
.transactions .intent {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  margin-left: 1rem;
  position: relative;
}
.transactions .intent img {
  height: 4rem;
  width: 4rem;
  position: absolute;
  top: -0.75rem;
  right: -0.95rem;
  filter: invert(1);
}
.transactions .intent img:hover {
  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
}
@media (orientation: portrait) {
  .transactions .amount {
    flex: 1;
  }
  .transactions .time {
    display: none;
  }
}
.column-reverse {
  display: flex;
  flex-direction: column-reverse;
}
.row-reverse {
  display: flex;
  flex-direction: row-reverse;
}
[disabled] {
  opacity: 0.8;
}
.ad-medium-rectangle {
  background: #ccc;
  width: 300px;
  height: 250px;
}
.ad-large-rectangle {
  background: #ccc;
  width: 336px;
  height: 280px;
}
.ad-leaderboard {
  background: #ccc;
  width: 728px;
  height: 90px;
  margin: 0 auto;
  margin: 2rem auto;
}
.ad-large-skyscraper {
  background: #ccc;
  width: 300px;
  height: 600px;
}
.donations {
  display: flex;
  justify-content: space-between;
}
.donations .markdown {
  flex: 1;
  padding: 0 2rem;
}
.iota img {
  width: 100px;
  height: 100px;
  float: left;
  margin: -5.5rem 0 0 -135px;
}
.iota h2 {
  clear: both;
  margin-top: 2rem;
}
.iota iframe {
  margin-bottom: 1rem;
}
.iota .flex {
  padding: 1rem auto;
  display: flex;
  flex-wrap: wrap;
  align-content: space-between;
}
.iota .flex .separator {
  flex: 1;
}
@media (orientation: portrait) {
  .iota {
    margin: 1rem 0;
    float: none;
  }
  .iota iframe {
    width: 100%;
  }
}
.how-it-works img {
  width: 100px;
  height: 100px;
  float: left;
  margin: -5.5rem 0 0 -135px;
}
.how-it-works h2 {
  clear: both;
  margin-top: 2rem;
}
strong {
  background: #333;
  color: #fff;
  padding: 0.1rem 0.5rem;
  white-space: pre-wrap;
}
.privacy img {
  width: 100px;
  height: 100px;
  float: left;
  margin: -5.5rem 0 0 -135px;
}
.privacy h2 {
  clear: both;
  margin-top: 2rem;
}
.privacy ul {
  list-style: square outside none;
  margin: 0;
  padding: 0 0 0 2rem;
}
.privacy ul li {
  display: list-item;
  font-size: 1.2rem;
  padding-bottom: 0.75rem;
}
.back {
  position: absolute;
  font-size: 1.2rem;
  top: -7.5rem;
  right: 2rem;
}
.back img {
  width: 3.5rem;
  height: 3.5rem;
  float: none;
  margin: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease-in;
}
.back:hover img {
  opacity: 0.8;
}
div[role="group"][tabindex] {
  flex: 1;
}
.footer {
  text-align: center;
  padding: 4rem 2rem 1rem;
}
.wallet .highlight {
  background: rgba(0,0,0,0.05);
  padding: 2rem;
  margin: 2rem 0 3rem;
  transition: border 0.2s ease-in;
}
.wallet .highlight:hover {
  border-bottom: 0.5rem solid #333;
}
.wallet .highlight ul {
  padding: 2rem 0;
  text-align: center;
}
.wallet .highlight ul li {
  margin-bottom: 2rem;
  font-size: 1rem;
  display: inline-block;
}
.wallet .highlight ul a {
  font-size: 1.5rem;
  background: #333;
  color: #fff;
  text-decoration: none;
  padding: 0.5rem 1rem;
  margin-right: 1rem;
}
.wallet .highlight ul a:hover {
  background: #666;
}
hr {
  margin: 2rem 0;
  border: none;
  border-bottom: 0.25rem dashed #ddd;
}
@-moz-keyframes loader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes loader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@-o-keyframes loader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes loader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@-moz-keyframes shake {
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }
  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }
  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }
  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
}
@-webkit-keyframes shake {
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }
  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }
  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }
  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
}
@-o-keyframes shake {
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }
  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }
  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }
  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
}
@keyframes shake {
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }
  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }
  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }
  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
}
